html,
body {
  height: 100%;
  overflow: auto;
}
:focus:not(:focus-visible) {
  outline: none;
}

@font-face {
  font-family: 'BpmfGenSenRounded';
  src: url('https://static.kolable.com/public/fonts/BpmfGenSenRounded/BpmfGenSenRounded-R.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BpmfGenSenRounded';
  src: url('https://static.kolable.com/public/fonts/BpmfGenSenRounded/BpmfGenSenRounded-B.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
// ant design
.ant-btn {
  span {
    line-height: normal;
  }
  .anticon {
    line-height: 0;
  }
}

.ant-col.ant-form-item-control-wrapper {
  flex-grow: 1;
}

.audio-player-popover {
  &.ant-popover {
    right: 0px;
    z-index: 10001;

    .ant-popover-title {
      padding: 1rem 1.25rem;
    }

    .ant-popover-inner-content {
      position: absolute;
      bottom: 100%;
      right: 0px;
      padding: 0;
    }

    @media (max-width: 767px) {
      .ant-popover-arrow {
        display: none;
      }
    }
  }
}

.ant-popover {
  z-index: 10001;

  .ant-popover-title {
    padding: 1rem 1.25rem;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  @media (max-width: 767px) {
    .ant-popover-arrow {
      display: none;
    }
  }
}

.ant-modal-mask {
  z-index: 1003;
}

.ant-modal-wrap {
  z-index: 1003;
}

.ant-message {
  z-index: 2104;
}

.ant-select-dropdown {
  z-index: 1450;
}

.ant-input-number {
  width: 120px !important;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  line-height: 36px;
}

.ant-select-tree li {
  overflow: hidden;
  max-width: 432px;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f7f8f8;
}

// BraftEditor
.public-DraftEditorPlaceholder-root,
.public-DraftEditorPlaceholder-hasFocus {
  color: #cdcece;
}

.short-bf-content {
  height: 240px;
}

.fb_customer_chat_bubble_pop_in {
  bottom: 100px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #FCE5F2;
}
.ant-menu-item:active, .ant-menu-submenu-title:hover {
  background: #FCE5F2 !important;
}
.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: #FCE5F2 !important;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #FCE5F2 !important;
}
.pink-text {
  color: #e4007f;
  &:hover {
    color: #e4007f;
  }
}
.ant-checkbox-inner {
  border: 1px solid #000 !important;
}

.programPlanSticky {
  position: sticky;
  top: 40px;
}

.programPackagePlanSticky {
  position: sticky;
  top: 48px;
}

.projectPlanSticky {
  position: sticky;
  top: 110px;
}

.activityPlanSticky {
  position: sticky;
  top: 56px;
}
